
$(function(){
    antiJunkMail();

    updateScrollTopOffset();
    doEventPeriodically($(window), 'resize', 100, updateScrollTopOffset);

    $('.js-shrinkableHeader').imarcomShrinkableHeader({
        triggerShrinkOffset: 78,
        toggleSpeed: 0
    });

    initFakePlaceholders();

    // $('.js-headerSearch').on('click', function () {
    //     $('body').addClass('search-popup-opened');
    //     $('.searchPopup-input').focus();
    // });

    $('.js-searchPopup-close').on('click', function () {
        $('body').removeClass('search-popup-opened');
    });

    $('.navbar-mainMenuItem').hover(function () {
        $('body').addClass('hover-megamenu');
        $(this).addClass('navbar-mainMenuItem-hover');
    }, function () {
        $('body').removeClass('hover-megamenu');
        $(this).removeClass('navbar-mainMenuItem-hover');
    });

    // $('.hasMegaMenu').on('click', function (e) {
    //     if ($(window).width() >= 768) {
    //         e.preventDefault();
    //     }
    //     let wasActive = $(this).parent().hasClass('navbar-mainMenuItem-hover');
    //
    //     $('body').removeClass('hover-megamenu');
    //     $('.navbar-mainMenuItem-hover').removeClass('navbar-mainMenuItem-hover');
    //
    //     if (!wasActive) {
    //         $('body').addClass('hover-megamenu');
    //         $(this).parent().addClass('navbar-mainMenuItem-hover');
    //     }
    // });

    $('html').on('click', function (e) {
        if (!$(e.target).closest('.navbar-mainMenuItem').length || $(e.target).hasClass('megamenu')) {
            $('body').removeClass('hover-megamenu');
            $('.navbar-mainMenuItem-hover').removeClass('navbar-mainMenuItem-hover');
        }
    });

    $(document).keyup(e => {
        if (e.keyCode == 27) {
            $('body').removeClass('hover-megamenu');
            $('.navbar-mainMenuItem-hover').removeClass('navbar-mainMenuItem-hover');
        }
    })

    $('.navbar-toggler').on('click', function () {
        $('body').toggleClass('burgerMenu-opened');
    });

    if ($('.js-parallax').length) {
        let windowHeight = $(window).height();
        let parallaxHeight = $('.parallax').outerHeight();
        let parallaxOffset = $('.parallax').offset().top;
        let start = Math.max(parallaxOffset - windowHeight, 0);
        let end = parallaxOffset + parallaxHeight;

        doEventPeriodically($(window), 'scroll', 10, function () {
            let scroll = getScrollTop();
            let position = Math.min(Math.max(0, scroll - start), end - start); // number between minimum and maximum (absolute)
            position = 50 - position * 50 / (end - start); // same number converted to percentage
            position = Math.round(position * 100) / 100; // rounded to 2 decimals
            position = position * -1; // Make sure number is in negative
            $('.js-parallax img').css('top', position+'%');
        });
    }

    if ($('.js-testimonials').length) {
        function testimonialsSlideshow (bulletIndex) {
            let currentIndex = $('.testimonials-bullet').index($('.testimonials-bullet-active'));
            let newIndex = currentIndex += 1;

            if (bulletIndex !== undefined) {
                newIndex = bulletIndex;
            }

            if (newIndex > $('.testimonials-bullet').length-1) {
                newIndex = 0;
            }

            $('.testimonials-element-active').removeClass('testimonials-element-active');
            $('.testimonials-bullet-active').removeClass('testimonials-bullet-active');
            $('.testimonials-element').eq(newIndex).addClass('testimonials-element-active');
            $('.testimonials-bullet').eq(newIndex).addClass('testimonials-bullet-active');
        }

        let interval = window.setInterval(testimonialsSlideshow, $('.js-testimonials').data('time'));

        $('.js-testimonials-bullet').on('click', function () {
            clearInterval(interval);
            testimonialsSlideshow($('.testimonials-bullet').index($(this)));
            interval = window.setInterval(testimonialsSlideshow, $('.js-testimonials').data('time'));
        });
    }

    if ($('#cp_14396').length) {
        $('#fieldset_91258c2d181eaeeb01a8030e0b8d434f, #fieldset_a89e2cc563a59c7a766bc16e7152b5e9, #fieldset_51ebe3725d189f5416c07d71dd70d7d2, #fieldset_65faa96d5aaefee011a83b70c513941b, #fieldset_0308e8f49d06c8330ffc8cd43549daad').addClass('form_category_container-hidden');

        $('#cp_14396').on('change', function (a) {
            $('#fieldset_91258c2d181eaeeb01a8030e0b8d434f, #fieldset_a89e2cc563a59c7a766bc16e7152b5e9, #fieldset_51ebe3725d189f5416c07d71dd70d7d2, #fieldset_65faa96d5aaefee011a83b70c513941b, #fieldset_0308e8f49d06c8330ffc8cd43549daad').addClass('form_category_container-hidden');
            let newVal = $('#cp_14396').val();
            if (newVal == 'a551b365989fd3177f4940f7dbb831b9') { $('#fieldset_91258c2d181eaeeb01a8030e0b8d434f').removeClass('form_category_container-hidden'); } // Toit plat
            else if (newVal == '90fa4691975f0003f6334c8ac33de64e') { $('#fieldset_65faa96d5aaefee011a83b70c513941b').removeClass('form_category_container-hidden'); } // Toit incline
            else if (newVal == '5e72285952af1753471fbb9b3e3efd6d') { $('#fieldset_a89e2cc563a59c7a766bc16e7152b5e9').removeClass('form_category_container-hidden'); } // Abri d'auto
            else if (newVal == 'b7415f6d5c6b324d79bfb7f585a2ca34') { $('#fieldset_51ebe3725d189f5416c07d71dd70d7d2').removeClass('form_category_container-hidden'); } // Systeme au sol
            else if (newVal == '4d414d77af31f267b77150f8ca42b325') { $('#fieldset_0308e8f49d06c8330ffc8cd43549daad').removeClass('form_category_container-hidden'); } // System au mur
        });
    }


    // Projects

    $('#js-grid-mosaic-projects').cubeportfolio({
        search: '#js-search-mosaic-projects',
        filters: '#js-filters-mosaic-projects',
        layoutMode: 'mosaic',
        defaultFilter: '*',
        animationType: 'quicksand',
        gapHorizontal: 8,
        gapVertical: 8,
        gridAdjustment: 'responsive',
        mediaQueries: [{
            width: 1110,
            cols: 4,
        }, {
            width: 930,
            cols: 3,
        }, {
            width: 690,
            cols: 2,
            options: {
                gapHorizontal: 8,
                gapVertical: 8,
            }
        }, {
            width: 510,
            cols: 1,
        }],
        caption: 'fadeIn',
        displayType: 'sequentially',
        displayTypeSpeed: 80,
    });


    // News

    $('#js-grid-mosaic-news').cubeportfolio({
        filters: '#js-filters-mosaic-news',
        layoutMode: 'grid',
        defaultFilter: '*',
        animationType: 'quicksand',
        gapHorizontal: 8,
        gapVertical: 8,
        gridAdjustment: 'responsive',
        mediaQueries: [{
            width: 1110,
            cols: 4,
        }, {
            width: 930,
            cols: 3,
        }, {
            width: 690,
            cols: 2,
            options: {
                gapHorizontal: 8,
                gapVertical: 8,
            }
        }, {
            width: 510,
            cols: 1,
        }],
        caption: 'fadeIn',
        displayType: 'sequentially',
        displayTypeSpeed: 80,
    });

    $('#js-grid-mosaic-events').cubeportfolio({
        filters: '#js-filters-mosaic-events',
        layoutMode: 'grid',
        defaultFilter: '*',
        animationType: 'quicksand',
        gapHorizontal: 8,
        gapVertical: 8,
        gridAdjustment: 'responsive',
        mediaQueries: [{
            width: 1110,
            cols: 3,
        }, {
            width: 930,
            cols: 3,
        }, {
            width: 690,
            cols: 2,
            options: {
                gapHorizontal: 8,
                gapVertical: 8,
            }
        }, {
            width: 510,
            cols: 1,
        }],
        caption: 'fadeIn',
        displayType: 'sequentially',
        displayTypeSpeed: 80,
    });


    // Products

    $('#js-grid-mosaic-products').cubeportfolio({
        filters: '#js-filters-mosaic-products-flag, #js-filters-mosaic-products-cat',
        layoutMode: 'grid',
        defaultFilter: '*',
        animationType: 'quicksand',
        gapHorizontal: 12,
        gapVertical: 12,
        gridAdjustment: 'responsive',
        mediaQueries: [{
            width: 709,
            cols: 2
        }, {
            width: 10,
            cols: 1
        }],
        caption: 'fadeIn',
        displayType: 'sequentially',
        displayTypeSpeed: 80,
    });
});
